@import url(global.css);

.banner__container {
  height: calc(22vw * 1.72);
}

.banner__container img {
  object-fit: contain;
}

.line {
  height: 2px;
  width: 7.03125vw;
  /* margin: 0 auto; */
  background-color: #4d4d4d;
}

.home-title {
  text-transform: uppercase;
  color: #4d4d4d;
  font-weight: 500;
}

@media only screen and (max-width: 1200px) {
  h2.home-title {
    font-size: 20px;
  }
}

.bran__logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 8.4375vw;
  width: 100%;
}

.bran__logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.bran__logo:hover {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.bran__viewmore {
  font-size: calc(6px + (24 - 10) * ((100vw - 375px) / (1920 - 375)));
  padding: 10px 20px;
  border: 0.5px solid #383838;
}

.bran__viewmore:hover {
  color: var(--blue-dior);
  border-color: var(--blue-dior);
}

@media only screen and (max-width: 992px) {
  .content__wrapper .searchform {
    display: none;
  }

  .line {
    width: 71px;
    height: 1px;
  }
}

@media only screen and (max-width: 768px) {
  .searchform {
    width: 100% !important;
  }

  .bran__viewmore {
    font-size: smaller;
  }

  .bran-list__title {
    font-size: smaller;
  }

  .bran__logo-list > * {
    display: none;
    visibility: hidden;
    height: 0;
    margin: 0;
  }

  .bran__list-img img {
    opacity: 1 !important;
  }

  .bran__list-img {
    height: 13.4375vw !important;
  }

  .bran__list-name {
    font-size: small;
  }

  .bran__list-type {
    font-size: x-small;
  }
}

@media only screen and (max-width: 600px) {
  .bran__list-item {
    /* width: calc(50% - 10px) !important; */
    /* margin: 0 10px 10px 0px !important; */
  }

  .bran__list-img {
    height: 13.4375vw !important;
  }
}

.content__wrapper {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.products__content-wrapper {
  box-shadow: unset !important;
}

.bran__filter h3 {
  font-size: 1.25vw;
  line-height: 1.30208vw;
  margin-right: 16px;
}

.bran__filter .order__item-wrapper {
  width: 225px;
}

.bran__filter svg {
  margin-left: 10px;
}

.bran__filter .order__item-dropdown {
  padding: 0.375rem 0.75rem;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
}

.bran__filter .order__item-dropdown_menu .order__item-dropdown_items button {
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
}

.bran__filter
  .order__item-dropdown_menu
  .order__item-dropdown_items
  button:hover {
  color: var(--blue-dior);
}

.content__wrapper .searchform {
  width: 40%;
}

.bran__list-line {
  height: 1px;
  /* margin: 0 auto; */
  background-color: #ddd;
}

.bran__list-item {
  position: relative;
  /* width: calc(20% - 1.04167vw); */
  /* margin: 0 0.52083vw 1.04167vw; */
  background-color: #fafafa;
  border: unset;
}

.bran__list-item:hover a {
  color: #333;
}

.bran__list-img {
  height: 6.4375vw;
}

.bran__list-img img {
  opacity: 0.5;
}

.bran__list-item:hover img {
  opacity: 1;
}

.content__inner {
  padding: 1.5rem 0;
}

.products__content {
  width: 100%;
}

.products__menu {
  width: 17.70833vw;
  /* width: 20%; */
  margin-right: 3.125vw;
}

.web__directional {
  margin-bottom: 1.5rem;
}

.web__directional li {
  display: inline;
  font-size: 0.83333vw;
  line-height: 0.98958vw;
}

.web__directional li a {
  color: #a0a0a0;
  text-decoration: none;
}

.web__directional li:hover a {
  color: var(--blue-dior);
}

.web__directional li + li::before {
  content: "/";
  padding: 0 5px;
  color: #a0a0a0;
}

.product__filter {
  color: #383838;
}

.filter__block:not(:last-child) {
  /* margin-bottom: calc(15px + (20 - 15) * ((100vw - 375px)/ (1920 - 375))); */
}

.filter__block h3 {
  font-size: 1.25vw;
  line-height: 1.30208vw;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Playfair Display";
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}

.filter__block:hover h3 {
  color: var(--blue-dior);
}

.filter__block svg {
  height: 1.25vw;
  width: 1.25vw;
  transition: all 0.3s ease;
}

.svg-right {
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}

.filter__block:hover svg {
  stroke: var(--blue-dior);
}
/* .filter__inner{
                        padding-top: calc(10px + (24 - 20) * ((100vw - 375px)/ (1920 - 375)));
                        padding-bottom: calc(10px + (40 - 20) * ((100vw - 375px)/ (1920 - 375))); 
                    } */
.filter__list {
  max-height: 0;
  margin: calc(10px + (24 - 20) * ((100vw - 375px) / (1920 - 375))) 0;
  transition: max-height 0.6s ease;
}

.filter__list,
.filter__sub-list {
  overflow: hidden;
  overflow-y: auto;
}

.filter__item {
  position: relative;
  overflow: hidden;
  padding-bottom: 2px;
}

.filter__item p {
  font-size: 1.2vw;
  font-weight: 500;
}

.sub-item-cate {
  font-size: 1vw;
}

.filter__item:not(:last-child) {
  margin-bottom: calc(5px + (20 - 15) * ((100vw - 375px) / (1920 - 375)));
}

.filter__item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 28px;
  background: #383838;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.checkbox input {
  margin-left: calc(12px + (15 - 10) * ((100vw - 375px) / (1920 - 375)));
  margin-right: calc(4px + (15 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.checkbox label > div {
  margin-right: 5px;
  line-height: 22px;
}

.checkbox label {
  cursor: pointer;
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  /* line-height: 1; */
}

.checkbox span {
  font-size: 1vw;
  /* line-height: 1; */
  margin-right: 5px;
  color: #d4d4d4;
}

.filter__sub-item:hover {
  color: var(--blue-dior);
}

.products__list {
  width: calc(100% - 17.70833vw);
}

.filter__control-top {
  position: relative;
}

.filter__control-top,
.filter__control-bottom {
  padding-top: calc(15px + (28 - 15) * ((100vw - 375px) / (1920 - 375)));
  padding-bottom: calc(15px + (28 - 15) * ((100vw - 375px) / (1920 - 375)));
  border-bottom: solid 1px #383838;
  border-top: solid 1px #383838;
  display: flex;
  align-items: center;
}

.filter__control-top .order__item-dropdown {
  min-width: 249px;
}

.filter__number {
  color: #383838;
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: 1.2;
  display: flex;
  align-items: center;
  margin-right: calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.filter__number .text {
  color: inherit;
  margin-right: 6px;
}

.filter__number span {
  display: inline-block;
  color: inherit;
}

.filter__number .number span:first-child {
  position: relative;
}

.filter__number .number span:first-child::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #383838;
  transition: all 0.3s ease;
}

.filter__number .number span:last-child {
  color: #a0a0a0;
}

.filter__pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.pagination-item {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(35px + (45 - 35) * ((100vw - 375px) / (1920 - 375)));
  height: calc(35px + (45 - 35) * ((100vw - 375px) / (1920 - 375)));
  background: #d4d4d4;
  color: #fff;
  margin: 0 calc(2.5px + (13 - 2.5) * ((100vw - 375px) / (1920 - 375)));
}

.pagination-item.next,
.pagination-item.previous {
  background: #fff;
  border: solid 1px #383838;
  color: #383838;
}

.pagination-item.active {
  cursor: default;
}

.pagination-item.active {
  background: #383838;
}

.filter__item-wrapper {
  display: none;
  width: calc(50% - 11px);
}

.filter__dropdown {
  position: relative;
  width: 100%;
  padding: 12px calc(12px + (18 - 12) * ((100vw - 375px) / (1920 - 375))) 8px;
  color: #383838;
  background-color: #fff;
  border: solid 1px #383838;
  font-weight: 400;
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(17px + (25 - 17) * ((100vw - 375px) / (1920 - 375)));
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.filter__dropdown svg {
  display: inline-block;
  margin-left: calc(0px + (60 - 0) * ((100vw - 375px) / (1920 - 375)));
  width: calc(16px + (18 - 12) * ((100vw - 375px) / (1920 - 375)));
  height: calc(16px + (18 - 12) * ((100vw - 375px) / (1920 - 375)));
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1200px) {
  .content__inner {
    padding: 1.25rem 0;
  }

  .web__directional {
    margin-bottom: 1.25rem;
  }

  .web__directional li {
    font-size: 14px;
    line-height: 18px;
  }

  .filter__control-top {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .filter__number,
  .filter__pagination {
    margin-top: calc(10px + (10 - 20) * ((100vw - 375px) / (1920 - 375)));
  }
}

@media only screen and (max-width: 992px) {
  .bran__filter h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .bran__filter .order__item-dropdown,
  .bran__filter .order__item-dropdown_menu .order__item-dropdown_items button {
    font-size: 12px;
  }

  .products__menu {
    width: 200px;
    margin-right: 30px;
  }

  .web__directional li {
    font-size: 12px;
    line-height: 14px;
  }

  .web__directional li + li::before {
    /* padding: 0 5px 0 0; */
  }

  .filter__block svg {
    height: 14px;
    width: 14px;
  }

  .filter__block h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .filter__item p,
  .sub-item-cate,
  .checkbox span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .filter__number {
    margin-right: 0;
  }

  .bran__filter {
    flex: wrap;
    width: 100%;
    justify-content: space-between;
    /* margin-bottom: 9px; */
  }

  .bran__filter h3 {
    font-size: 16px;
    line-height: 25px;
    width: 100%;
    margin-right: 0;
  }

  .bran__filter .order__item-wrapper {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .filter__control-top .order__item-dropdown {
    min-width: unset;
  }

  .bran__filter .order__item-dropdown {
    font-size: 12px;
  }

  .bran__filter .order__item-dropdown_menu .order__item-dropdown_items button {
    font-size: 12px;
  }

  .content__inner {
    padding: 9px 0;
  }

  .web__directional {
    margin-bottom: 9px;
  }

  .products__content {
    flex-direction: column;
  }

  .products__menu {
    width: 100%;
    margin-right: 0;
  }

  .filter__block:not(:last-child) {
    margin-bottom: 0;
  }

  .products__list {
    width: 100%;
  }

  .filter__control-top,
  .filter__control-bottom {
    border-top: solid 1px #ddd;
    border-bottom: solid 1px #ddd;
    padding: 9px 0;
  }

  .filter__control-bottom {
    border-top: none;
    justify-content: space-between;
  }

  .filter__item-wrapper {
    margin-right: 0;
    width: calc(50% - 11px);
  }

  .filter__dropdown {
    border: solid 1px #d4d4d4;
  }

  .filter__pagination {
    width: calc(50% - 11px);
    justify-content: unset;
    flex: unset;
  }

  .pagination {
    width: 100%;
    /* justify-content: space-between; */
  }

  .pagination-item {
    width: calc(30px + (45 - 35) * ((100vw - 375px) / (1920 - 375)));
    height: calc(30px + (45 - 35) * ((100vw - 375px) / (1920 - 375)));
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .pagination-item {
    width: 26px;
    height: 26px;
    font-size: 12px;
  }
}

/* Signup */
.signup__wrapper {
  margin-bottom: 300px;
  /* box-shadow: 0 10px 30px rgb(0 0 0 / 10%); */
}

.signup__wrapper .web__directional {
  padding-bottom: 9px;
  border-bottom: 1px solid #ddd;
}

.signup__inner {
  max-width: 442px;
  background: #f8f8f8;
  color: #383838;
}

.signup__inner input,
.signup__inner button {
  font-size: calc(4px + (24 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.signup__inner input,
.signup__inner select {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

.signup__inner input:focus-within {
  outline: none;
  border: 1px solid var(--blue-dior);
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.signup__inner h3 {
  font-size: calc(10px + (24 - 10) * ((100vw - 375px) / (1920 - 375)));
  color: #383838;
  margin-bottom: 1.5rem;
}

.signup__inner button:focus {
  outline: none;
}

.signup__inner button {
  background-color: #4d4d4d;
  color: #fff;
  /* border: 1px solid #333; */
  padding: 5px 10px;
  cursor: pointer;
}

.signup__inner button:hover {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.verifycode-signup {
  visibility: hidden;
}

.verifycode-signup input {
  width: 65%;
}

.form-basic {
  position: relative;
  margin-bottom: 1rem;
}

.form-basic input,
.form-basic select {
  padding:  0.5rem 1rem;
}

.form-input {
  position: relative;
  border-bottom: 1px solid #ddd;
}

.form-input input {
  border: unset !important;
  padding: 0.5rem 2rem;
}

.form-input input:focus {
  box-shadow: unset !important;
}

.form-input span {
  position: absolute;
  left: 0;
  height: 100%;
  align-items: center;
  display: flex;
}

.signup-info {
  font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: 25px;
  color: #383838;
  margin-bottom: calc(10px + (17 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.signup-block:not(:last-child) {
  margin-bottom: calc(22px + (17 - 10) * ((100vw - 375px) / (1920 - 375)));
}

@media only screen and (max-width: 768px) {
  .signup__inner h3 {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

  .signup__inner input,
  .signup__inner button {
    font-size: 14px;
  }
}

.product__grid-wrapper {
  padding: calc(16px + (78 - 16) * ((100vw - 375px) / (1920 - 375))) 0
    calc(30px + (25 - 30) * ((100vw - 375px) / (1920 - 375)));
}

.product__grid-title {
  text-transform: uppercase;
  font-size: calc(14px + 14 * ((100vw - 375px) / 1545));
  margin-bottom: calc(16px + (78 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.product__grid-title:not(:first-child) {
  margin-top: calc(16px + (78 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.product__grid-title::after,
.product__grid-title::before {
  background-color: #ddd;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 10%;
}

.product__grid-title::before {
  right: 0.5em;
  margin-left: -50%;
}

.product__grid-title::after {
  left: 0.5em;
  margin-right: -50%;
}

.product {
  position: relative;
  margin-right: 4px;
  margin-bottom: 6px;
  background-color: #fff;
}

.product-3 {
  width: calc(100% / 3 - 4px);
}

.product-4 {
  width: calc(100% / 4 - 4px);
}

.product-heart {
  margin-right: 10px;
  height: 24px;
  width: 24px;
}

.product-label {
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
}
/* .product-label span{
            background-color: #ececec;
            padding: 5px 10px;
            margin-right: 10px;
            border:1px solid #ddd;
        } */
.product-label span {
  background-color: #ac0833;
  padding: 2px 6px;
  margin-right: 10px;
  font-size: 14px;
  color: #fff;
}

.product-disc {
  position: absolute;
  top: 0;
  left: 2.70833vw;
  width: 2.08333vw;
  height: 2.08333vw;
  font-size: calc(16px + (22 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(22px + (32 - 22) * ((100vw - 375px) / (1920 - 375)));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: red;
  height: calc(30px + (45 - 30) * ((100vw - 375px) / (1920 - 375)));
  width: calc(45px + (65 - 45) * ((100vw - 375px) / (1920 - 375)));
  color: #fff;
}

.product-disc:before {
  border-top: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375))) solid
    red;
  border-left: calc(22.5px + (32.5 - 22.5) * ((100vw - 375px) / (1920 - 375)))
    solid transparent;
  border-right: calc(22.5px + (32.5 - 22.5) * ((100vw - 375px) / (1920 - 375)))
    solid transparent;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  bottom: calc(-15px + (-20 - -15) * ((100vw - 375px) / (1920 - 375)));
  width: 0;
}

.product:hover {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.product-img {
  box-sizing: border-box;
  height: calc(233px + (468 - 233) * ((100vw - 375px) / (1920 - 375)));
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.fill {
  fill: #333;
}

.product-img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  -o-object-fit: contain;
  object-fit: contain;
}

.product__info-benefit a {
  font-size: 0.83333vw;
  line-height: 1.04167vw;
  color: var(--blue-dior);
}

.product__info-detail {
  padding: 10px calc(7px + (20 - 7) * ((100vw - 375px) / (1920 - 375))) 15px;
}

.product-brand {
  color: #383838;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0.52083vw;
}

.product-name {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
  margin-bottom: 1.04167vw;
}

.product-subprice {
  font-size: 16px;
  line-height: 19px;
  text-decoration: line-through;
  color: #a0a0a0;
}

.product-price {
  font-size: 20px;
  line-height: 24px;
  color: #4d4d4d;
  /* margin-bottom: 0.625vw; */
}

.product-btn {
  height: calc(43px + (54 - 43) * ((100vw - 375px) / (1920 - 375)));
  margin-top: 0.78125vw;
}

.product-btn button {
  transform: scale(1);
  opacity: 1;
  transition: all 0.5s ease;
  background: #fff;
  color: var(--blue-dior);
  border: 1px solid #383838;
  display: inline-block;
  font-size: 0.9375vw;
  line-height: 1;
  padding: 0.75521vw 2.8125vw;
  border: 1px solid #383838;
}

.product-btn:hover a {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

@media only screen and (max-width: 1200px) {
  .product-subprice {
    font-size: 12px;
    line-height: 14px;
  }

  .product-price {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 992px) {
  .product__grid-title {
    font-size: 18px;
  }

  .product-3,
  .product-4 {
    width: calc(50% - 4px);
  }

  .product-btn button {
    font-size: 1.81452vw;
    padding: 1.46169vw 5.44355vw;
  }

  .product-heart svg {
    width: 20px;
    height: 20px;
  }

  .product-label span {
    font-size: 10px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 768px) {
  .product-brand {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 4px;
  }

  .product-name {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .product-btn {
    display: none;
  }
}

/* Product detail */
.product__detail-card {
  background-color: #fff;
  border: none;
}

.product__detail-slider {
  width: 100%;
  /* border: 1px solid #ddd;
    padding: 1rem; */
}

ul.lightSlider {
  list-style: none outside none;
  padding-left: 0;
  margin-bottom: 0;
}

ul.lightSlider li {
  display: block;
  float: left;
  margin-right: 6px;
  cursor: pointer;
}

.product__detail-content img {
  display: block;
  height: auto;
  width: 100%;
  object-fit: contain;
}

hr {
  color: #ddd;
}

.badge {
  padding: 5px !important;
  padding-bottom: 6px !important;
}

.badge i {
  font-size: 10px;
}

.profile__detail-image {
  width: 35px;
}

.date span {
  font-size: 12px;
}

.product__detail-buttons button {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  border: 1px solid #333;
  padding: calc(13px + (24 - 13) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-buttons button:hover {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.btn-black {
  background-color: #4d4d4d;
  border: 1px solid #4d4d4d;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-black:hover {
  background-color: #fff !important;
  color: #333 !important;
  transition: 0.5s;
}

.product__detail-link:hover {
  color: var(--blue-dior) !important;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.bullet-text {
  font-size: calc(16px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
}

.card-body {
  padding: 0.3rem 0.3rem 0.2rem;
}

.about {
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(17px + (24 - 17) * ((100vw - 375px) / (1920 - 375)));
}

.about > * {
  color: #383838;
}

.product__detail-content {
  margin-bottom: calc(30px + (54 - 30) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-image {
  padding-right: 1.5rem !important;
}

.size-guide {
  font-size: calc(12px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
}
.product__detail-info a:hover svg,
.product__detail-info a:hover,
.product__detail-info a:hover .text,
.product__detail-info a:hover .icon {
  color: var(--blue-dior);
  stroke: var(--blue-dior);
}

.product__detail-info .product-rating:hover {
  color: inherit !important;
  stroke: none !important;
}

.product__detail-info a .stars:hover svg,
.product__detail-info a .stars:hover .icon {
  color: orange;
  stroke: none;
}

.product__detail-id {
  margin-bottom: calc(5px + (11 - 5) * ((100vw - 375px) / (1920 - 375)));
  font-weight: 600;
}

.product__detail-id a {
  width: 50%;
}

.product-id span:first-child {
  margin-right: 1rem;
}

.product__detail-action {
  cursor: pointer;
}

.product__detail-action .icon,
.product__detail-action .text {
  vertical-align: middle;
  display: inline-block;
}

.product__detail-action .icon,
.product__detail-action .icon svg {
  height: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
  width: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-action .text {
  font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-name {
  font-size: calc(20px + (45 - 20) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (54 - 24) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: calc(5px + (11 - 5) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-brand {
  margin-bottom: calc(21px + (44 - 21) * ((100vw - 375px) / (1920 - 375)));
  font-size: calc(18px + (35 - 18) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(22px + (42 - 22) * ((100vw - 375px) / (1920 - 375)));
  font-weight: 500;
}

.product__detail-discount {
  color: var(--blue-dior);
  font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: 25px;
  margin-bottom: calc(30px + (37 - 30) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-action {
  margin-bottom: calc(5px + (8 - 5) * ((100vw - 375px) / (1920 - 375)));
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(17px + (24 - 17) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-action .icon {
  margin-right: calc(7px + (22 - 13) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-action a:not(:last-child) {
  margin-right: calc(18px + (144 - 18) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-bottom input,
.product__detail-bottom select {
  border-radius: 5px;
}

.product__detail-bottom input {
  padding: 0 4px;
  border: 1px solid #333;
  font-size: calc(14px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-bottom input:focus {
  outline: none;
}

.product__detail-options {
  margin-bottom: calc(30px + (37 - 30) * ((100vw - 375px) / (1920 - 375)));
}

.sales-value {
  color: var(--blue-dior);
}

.options-wrapper select {
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
}

.option-title {
  position: relative;
  font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
  width: 173px;
}

.option-title input {
  position: absolute !important;
  height: 100%;
}

.option-title label {
  margin-left: 26px;
}

.product__detail-options .options-wrapper {
  position: relative;
  /*width: 7.5rem;*/
}

.product__detail-options .options-wrapper select {
  font-size: calc(14px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.options-wrapper .options,
.options-wrapper svg {
  cursor: pointer;
}

.options-wrapper svg {
  transition: all 0.3s ease;
}

.options {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.product__detail-options .options-wrapper svg {
  position: absolute;
  right: 4px;
  height: 100%;
  top: 0;
}

.option-price label {
  /* cursor: pointer; */
}

.product__detail-price .form-check-input {
  margin-right: 0 !important;
}

.product__detail-price {
  margin-bottom: calc(9px + (37 - 30) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-price * {
  font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
}
/* .price{
        font-size: calc(22px + (24 - 16) * ((100vw - 375px)/ (1920 - 375)));
    } */
.saleprice p {
  font-size: calc(12px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.product__detail-price p {
  color: #707070;
  /* width: calc(100px + (150 - 100) * ((100vw - 375px)/ (1920 - 375))); */
}

.product__detail-price span {
  color: var(--blue-dior);
}

.product__detail-price p.line-through {
  color: #a0a0a0;
}

.product__sale-code {
  position: relative;
  cursor: pointer;
  background-color: #333;
  padding: 6px 0;
}

.circle-left,
.circle-right {
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.circle-left {
  position: absolute;
  left: -8px;
  top: calc(50% - 8px);
}

.circle-right {
  position: absolute;
  right: -8px;
  top: calc(50% - 8px);
}

.product__sale-code .text {
  margin-left: 16px;
}

.product__sale-code svg {
  margin-right: 16px;
  stroke: #fff;
  transition: all 0.3s ease;
}

.product__sale-bottom {
  width: 100%;
  border: 1px solid #333;
  background-color: #fff;
  padding: 0 10px;
}

.product__sale-bottom span {
  color: #383838;
}

.product__sale-bottom .getcode {
  padding: 0 10px;
  cursor: pointer;
  color: var(--blue-dior);
  background-color: #fff;
  border: 1px solid #333;
}

.product__sale-bottom .getcode:focus {
  outline: unset;
}

.product__sale-bottom .getcode:hover {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.product__sale-code .text {
  color: #fff;
}

.product__images-slider {
  margin-bottom: calc(45px + (54 - 30) * ((100vw - 375px) / (1920 - 375)));
}

.detail-title {
  position: relative;
  font-size: calc(14px + (24 - 14) * ((100vw - 375px) / (1920 - 375)));
  padding: calc(10px + (24 - 10) * ((100vw - 375px) / (1920 - 375))) 0
    calc(6px + (20 - 6) * ((100vw - 375px) / (1920 - 375)));
  color: #333;
  font-weight: 500;
}

.product__tab-control a {
  cursor: pointer;
}

.product__tab-control a:hover {
  color: var(--blue-dior);
}

.product__tab-control a:before {
  content: "";
  position: absolute;
  width: 0;
  height: calc(2px + (3 - 2) * ((100vw - 375px) / (1920 - 375)));
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: #383838;
  transition: all 0.8s ease;
}

.product__tab-control a.active:before {
  width: calc(100% - 10px);
}

.product-comment,
.product-detail {
  /* margin-bottom: calc(52px + (80 - 52) * ((100vw - 375px)/ (1920 - 375))); */

  padding: calc(19px + (66 - 19) * ((100vw - 375px) / (1920 - 375))) 0
    calc(0px + (132 - 77) * ((100vw - 375px) / (1920 - 375)));
  /* background: #f8f8f8; */
  /* height: calc(360px + (738 - 360) * ((100vw - 375px) / (1920 - 375))); */
  overflow: hidden;
  position: relative;
  transition: height 0.5s ease;
}

.detail-list * {
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: 1.2;
  color: #383838;
}

.detail-item {
  /*flex: 1;*/
  flex-basis: calc(50% - 35px / 2);
  display: flex;
  border-bottom: solid 1px #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: calc(0px + (37 - 0) * ((100vw - 375px) / (1920 - 375)));
}

.detail-item:nth-child(2n + 2) {
  margin-left: 35px;
}

.detail-item span:first-child {
  width: 50%;
  padding-right: 8px;
}

.detail-item span {
  width: 68%;
}

.product__tab-content {
}

/* .content-tab {
  display: none;
} */

.product__tab-content .active {
  display: block;
}

.product-comment button,
.product-comment .upload {
  padding: 4px 10px;
  cursor: pointer;
  background-color: #333;
  border: 1px solid #333;
  color: #fff !important;
}

.tab__content-block {
  margin-bottom: calc(80px + (70 - 27) * ((100vw - 375px) / (1920 - 375)));
}
.product-detail video,
.product-detail iframe {
  max-width: 100%;
}
.product-detail .title {
  font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: 1.2;
  margin-bottom: calc(10px + (30 - 10) * ((100vw - 375px) / (1920 - 375)));
  font-weight: 600;
}
.product-detail .description img {
  max-width: 100%;
  height: auto;
}
/*.product-detail .description-mobile {
    display: none;
}
@media only screen and (max-width: 768px) {
    .product-detail .description {
        display: none;
    }
    .product-detail .description-mobile {
        display: block;
    }
}*/
.input-title {
  font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: 25px;
  color: #383838;
  margin-bottom: calc(10px + (17 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.tab__content-block input,
.tab__content-block textarea {
  border: solid 1px #d4d4d4;
  font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
  height: auto;
  /* line-height: calc(48px + (57 - 48) * ((100vw - 375px)/ (1920 - 375))); */
  padding: 0 calc(13px + (31 - 13) * ((100vw - 375px) / (1920 - 375)));
}

.tab__content-block textarea {
  padding: calc(16px + (57 - 48) * ((100vw - 375px) / (1920 - 375)))
    calc(13px + (31 - 13) * ((100vw - 375px) / (1920 - 375)));
}

.upload-image span {
  font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
}

.user-comment:not(:last-child) {
  /*border-bottom: 1px solid #ddd;*/
  padding-bottom: 1rem;
}

.user-comment * {
  font-size: calc(16px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
}

.user__comment-time {
  font-size: calc(
    12px + (16 - 14) * ((100vw - 375px) / (1920 - 375))
  ) !important;
  color: #999;
}
.user__comment-role {
  font-size: calc(
    12px + (16 - 14) * ((100vw - 375px) / (1920 - 375))
  ) !important;
  background: #ac0833;
  color: white;
  border-radius: 5px;
}

.user-comment .image .image-list {
  height: 130px;
}

.user-comment .image .image-list li {
  cursor: pointer;
  height: 100%;
  width: 130px;
  text-align: center;
  margin-right: 10px;
}

.user-comment .image .image-list img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.user-comment .listreply {
  position: relative;
  padding: 10px 15px 0 12px;
  background: #f8f8f8;
  border: 1px solid #dfdfdf;
}

.user-comment .listreply::before,
.user-comment .listreply::after {
  top: -20px;
  left: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.user-comment .listreply::before {
  border-color: rgba(238, 238, 238, 0);
  border-bottom-color: #f8f8f8;
  border-width: 11px;
  margin-left: -11px;
  z-index: 1;
}

.user-comment .listreply::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #dfdfdf;
  border-width: 10px;
  margin-left: -10px;
}

.user-comment .listreply .reply:not(:last-child) {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.reply-action .favor::before {
  float: left;
  content: "-";
  color: #999;
  font-size: 14px;
  margin-right: 5px;
}
.reply-action .favor .count {
  cursor: pointer;
}
.reply-action .favor .count:hover {
  text-decoration: underline;
}

.product__grid-4 .product {
  width: calc(25% - 4px) !important;
}

@media only screen and (max-width: 992px) {
  .option-title {
    width: 137px;
  }

  .product__sale-code svg {
    right: 9px;
  }
}

@media only screen and (max-width: 768px) {
  .product-id span:first-child,
  .product__detail-id .stars {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .product-id,
  .product-rating {
    width: 100% !important;
  }

  .product-id span:first-child,
  .product-rating .stars {
    width: auto;
  }

  .option-title {
    width: 123px;
  }

  .option-title label {
    margin-left: 24px;
  }

  .product__detail-image {
    padding-right: 0 !important;
  }

  .product__detail-info {
    margin-top: 1.5rem;
  }

  .product__detail-options .options {
    justify-content: space-between;
  }

  .product__detail-options .options-wrapper {
    /*width: 6.5rem;*/
  }

  .option-quality input {
    padding: 4px;
  }

  .product__detail-buttons button {
    font-size: 14px;
  }

  .product__detail-slider {
    border: unset;
    padding: unset;
  }

  .product__sale-content .text {
    font-size: 14px;
  }

  .detail-item {
    flex-basis: 100%;
  }

  .detail-item:nth-child(2n + 2) {
    margin-left: 0;
  }

  .product__grid-4 .product {
    width: calc(50% - 4px) !important;
  }
}

/* Blog */
.blog-content {
  /*width: calc(100% - 17.70833vw - 3.125vw);*/
}
.blog-content img,
.blog-content video {
  max-width: 100%;
  height: auto;
}
.blog-content iframe {
  max-width: 100%;
}

.blog-header {
  font-size: calc(22px + (50 - 24) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(35px + (60 - 35) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: calc(30px + (40 - 30) * ((100vw - 375px) / (1920 - 375)));
  color: #383838;
}

.blog__content-detail ul {
  padding-left: calc(42px + (56 - 42) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: calc(30px + (40 - 30) * ((100vw - 375px) / (1920 - 375)));
}

.blog__content-detail li {
  font-size: calc(18px + (28 - 20) * ((100vw - 375px) / (1920 - 375)));
  color: #383838;
  line-height: calc(28px + (40 - 30) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
}

.blog__sub-content {
  list-style: disc;
}
/* Login */

/* Reset focus */
.login-form input:focus {
  border-color: unset;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  outline: unset;
}

.login-form input {
  border-radius: 0;
  border: 1px solid #333;
}

.login button:hover,
.login-form input:focus {
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
}

.login button {
  cursor: pointer;
  color: #fff;
  background-color: #4d4d4d;
  border: 1px solid #4d4d4d;
  padding: 0.5rem 1rem;
}

/* Cart */
.cart-title {
  font-family: "Playfair Display";
  font-size: calc(20px + (35 - 20) * ((100vw - 375px) / (1920 - 375)));
  line-height: 1.2;
  padding-bottom: calc(5px + (20 - 5) * ((100vw - 375px) / (1920 - 375)));
  /* border-bottom: solid 1px #ddd; */
  margin-bottom: calc(28px + (30 - 28) * ((100vw - 375px) / (1920 - 375)));
  color: #383838;
}

.primary-cartcontent {
  flex: 1;
}

.cart-head {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
  display: flex;
  padding: calc(15px + (26 - 15) * ((100vw - 375px) / (1920 - 375))) 0
    calc(10px + (19 - 10) * ((100vw - 375px) / (1920 - 375)));
  /* background: #ddd; */
  border-bottom: 1px solid #ddd;
}

.cart-head span:first-child {
  flex: 1;
  margin-left: calc(10px + (24 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.cart-head span:not(:first-child),
.cart-product--col {
  flex-basis: calc(50px + (265 - 50) * ((100vw - 375px) / (1920 - 375)));
}

.cart-product {
  position: relative;
  padding-bottom: calc(18px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));
  border-bottom: 1px solid #ddd;
}

.cart-product__image {
  width: calc(72px + (190 - 117) * ((100vw - 375px) / (1920 - 375)));
  height: calc(72px + (190 - 117) * ((100vw - 375px) / (1920 - 375)));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: solid 1px #ddd;
  background: #f8f8f8;
  margin-right: 15px;
}

.cart-product__image img {
  max-height: 100%;
  max-width: 100%;
}

.cart-product__info {
  flex: 1;
  display: flex;
  align-items: center;
}

.cart-product__info * {
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(17px + (24 - 17) * ((100vw - 375px) / (1920 - 375)));
  color: inherit;
}

.cart-product {
  margin-bottom: calc(18px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));
}

.cart-product--auto {
  flex: 1;
}

.cart-product__info input {
  border: 1px solid #ddd;
  text-align: center;
  padding: 4px 0;
  width: 48px;
  height: calc(37px + (27 - 37) * ((100vw - 375px) / (1920 - 375)));
}

.cart-product__remove {
  cursor: pointer;
  position: absolute;
  /* height: 100%; */
  right: 0;
  align-items: center;
}

.continue-shopping {
  cursor: pointer;
  border: 1px solid #333;
  padding: 0.5rem 1rem;
  font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));
  color: #fff !important;
  background-color: #4d4d4d;
}

.continue-shopping:hover {
}

.secondary-cartcontent {
  width: 375px;
  margin-left: 40px;
  color: #383838;
}

.order-summary {
  padding: calc(15px + (26 - 15) * ((100vw - 375px) / (1920 - 375)));
  background: #f8f8f8;
}

.order-summary-title {
  margin-bottom: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
}

.payment-coupon {
  margin-bottom: calc(22px + (50 - 22) * ((100vw - 375px) / (1920 - 375)));
  color: #383838;
}

.input-coupon {
  flex: 1;
  border: none;
  position: relative;
  overflow: hidden;
}

.input-coupon input {
  height: 38px;
  font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(48px + (57 - 48) * ((100vw - 375px) / (1920 - 375)));
  padding: 0 calc(13px + (31 - 13) * ((100vw - 375px) / (1920 - 375)));
  width: 100%;
  background-color: #fff;
  color: #707070;
}

.input-coupon:before {
  content: "";
  position: absolute;
  border: 7px dashed #ddd;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  pointer-events: none;
}

.submit-coupon {
  cursor: pointer;
  margin-left: 20px;
  padding: 0 10px;
  background-color: #4d4d4d;
  color: #fff;
}

.checkout {
  cursor: pointer;
  line-height: calc(40px + (52.5 - 50) * ((100vw - 375px) / (1920 - 375)));
  font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));
  background-color: #4d4d4d;
  color: #fff;
}

@media only screen and (max-width: 1200px) {
  .cart-product--col,
  .cart-head span:not(:first-child) {
    flex-basis: calc(28px + (265 - 50) * ((100vw - 375px) / (1920 - 375)));
  }
}

@media only screen and (max-width: 992px) {
  .primary-cartcontent {
    width: 100%;
    flex: unset;
    margin-bottom: 1rem;
  }

  .cart-product--col,
  .cart-head span:not(:first-child) {
    flex-basis: calc(88px + (265 - 50) * ((100vw - 375px) / (1920 - 375)));
  }

  .secondary-cartcontent {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .cart-head {
    display: none;
  }

  .cart-product__info {
    flex-wrap: wrap;
  }

  .cart-product--auto,
  .cart-product--col {
    width: 100%;
    flex: unset;
    flex-basis: unset !important;
  }

  .cart-product--col,
  .cart-head span:not(:first-child) {
    flex-basis: calc(44px + (265 - 50) * ((100vw - 375px) / (1920 - 375)));
  }

  .cart-product__info .totalprice {
    display: flex;
    justify-content: flex-end;
  }

  .cart-product__remove {
    align-items: unset;
  }

  .continue-shopping {
    width: 100%;
  }
}
/* Payment */
.billing-summary {
  padding: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)))
    calc(30px + (65 - 30) * ((100vw - 375px) / (1920 - 375)))
    calc(30px + (47 - 30) * ((100vw - 375px) / (1920 - 375)));
  background: #f8f8f8;
  color: #383838;
}

.billing-total .attr {
  width: calc(100% / 3);
}

.billing-total span {
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
}

.billing-total .text {
  font-size: calc(18px + (20 - 18) * ((100vw - 375px) / (1920 - 375)));
}

.billing-total .value {
  font-size: calc(20px + (20 - 18) * ((100vw - 375px) / (1920 - 375)));
}

.billing-summary .title {
  font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: 25px;
  color: #383838;
  padding: calc(10px + (24 - 10) * ((100vw - 375px) / (1920 - 375))) 0
    calc(6px + (20 - 6) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: calc(10px + (17 - 10) * ((100vw - 375px) / (1920 - 375)));
  border-bottom: 2px solid #4d4d4d;
}

.payment-info__line {
  margin-bottom: calc(10px + (30 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.payment-info__line input {
  border: solid 1px #d4d4d4;
  font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
  height: auto;
  line-height: calc(48px + (57 - 48) * ((100vw - 375px) / (1920 - 375)));
  padding: 0 calc(13px + (31 - 13) * ((100vw - 375px) / (1920 - 375)));
}

.payment-checkout a {
  cursor: pointer;
  background-color: #4d4d4d;
  color: #fff !important;
  padding: 0;
  width: 288px;
  line-height: calc(50px + (52.5 - 50) * ((100vw - 375px) / (1920 - 375)));
  font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.payment-method .payment-info__line {
  border-bottom: solid 1px #d4d4d4;
}

.payment-info__line .location {
  position: relative;
  width: calc(100% / 3 - 8px);
}

.payment-bank {
  margin-bottom: 10px !important;
}

.payment-info__line .location select {
  cursor: pointer;
  border: solid 1px #d4d4d4;
  font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
  height: calc(48px + (57 - 48) * ((100vw - 375px) / (1920 - 375)));
  padding: 0 calc(13px + (31 - 13) * ((100vw - 375px) / (1920 - 375)));
  color: inherit;
}

.bank {
  margin-bottom: calc(18px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));
}

.bank-image {
  width: calc(68px + (190 - 117) * ((100vw - 375px) / (1920 - 375)));
  height: calc(68px + (190 - 117) * ((100vw - 375px) / (1920 - 375)));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: solid 1px #ddd;
  background: #f8f8f8;
  margin-right: 15px;
}

.bank-info {
  flex: 1;
}

.bank-info * {
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(17px + (24 - 17) * ((100vw - 375px) / (1920 - 375)));
}

.trans-input {
  border-color: #ddd !important;
}

.trans-input:focus-within {
  border-color: var(--blue-dior) !important;
}

@media only screen and (max-width: 1200px) {
  .billing-total .attr {
    width: 40%;
  }

  .payment-bank {
    margin-bottom: 0;
  }

  .payment-info__line .location {
    width: 100%;
    margin-bottom: calc(10px + (30 - 10) * ((100vw - 375px) / (1920 - 375)));
  }
}

@media only screen and (max-width: 992px) {
  .billing-total .attr {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .payment-checkout a {
    width: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .product__sale-code {
    padding: 15px 0;
  }
}

/* User Profile */
.user__profile-wrapper * {
  font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(17px + (24 - 17) * ((100vw - 375px) / (1920 - 375)));
}

.control-list li:hover a {
  color: var(--blue-dior);
}

.control-selectlist {
  position: relative;
  display: none;
}

.control-selectlist span {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
}

.tab-content {
  background: #f8f8f8;
  padding: 1rem;
  color: #383838;
}

.tab-content .title {
  font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: 25px;
  color: #383838;
  padding: calc(10px + (24 - 10) * ((100vw - 375px) / (1920 - 375))) 0
    calc(6px + (20 - 6) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: calc(10px + (17 - 10) * ((100vw - 375px) / (1920 - 375)));
}

.tabs-content .label {
  flex-basis: calc(50px + (265 - 50) * ((100vw - 375px) / (1920 - 375)));
}

.tabs-content .info {
  flex: 1;
}

.order-table th,
.order-table td {
  white-space: nowrap;
}

.edit-userinfo {
  cursor: pointer;
  background-color: #4d4d4d;
  color: #fff !important;
  /* line-height: calc(50px + (52.5 - 50) * ((100vw - 375px)/ (1920 - 375))); */
  font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));
}

.user__info-form {
  width: 26rem;
}

.tab-content .form-group {
  /* margin-bottom: 0; */
}

.select-form {
  position: relative;
}

.select-form span {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.control-list .active,
.control-list .active svg,
.control-list li:hover span,
.control-list li:hover span svg {
  color: var(--blue-dior);
  stroke: var(--blue-dior);
}

@media only screen and (max-width: 992px) {
  .order-filter {
    width: 100%;
    margin-bottom: 10px !important;
  }

  .order-filter label {
    flex-basis: 100px;
  }

  .order-filter .filter-type {
    flex: 1;
  }
}

@media only screen and (max-width: 768px) {
  .control-selectlist {
    display: block;
  }

  .control-list {
    display: none;
  }

  .tabs-content .label {
    flex-basis: 136px;
  }

  .user__info-form {
    width: 100%;
  }
}

.notification-list {
  /* max-height: ; */
}

.notification-list .notification {
  position: relative;
}

.notification-list .notification .type {
}

.notification-list .notification .time {
  font-size: calc(12px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
}

.notification-list .notification .content {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 800px;
}

.notification-list .notification:not(:last-child) {
  border-bottom: 1px solid #dfdfdf;
}

.notification-list .notification .remove {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0;
}

.notification-list .notification:hover {
  /*background-color: white;*/
}

.search-notifications .header-searchform {
  border-color: #f8f8f8;
}

.search-notifications .header-searchform::after {
  border-color: #f8f8f8;
}

.search-notifications .header-searchform .text input::placeholder {
  color: #f8f8f8;
}

@media only screen and (max-width: 1400px) {
  .search-notifications .header-searchform input {
    width: 12.5rem;
  }
}

.chat-box-min {
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  width: 200px;
  height: 40px;
  padding: 0px 15px;
  bottom: 0;
  right: 10px;
  position: fixed;
  border: 0px;
  color: white;
  background-color: var(--red-noti);
}

.chat-box-min .icon,
.chat-box-min .dot-wrapper {
  display: none;
}

@media only screen and (max-width: 768px) {
  .chat-box-min {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    text-align: center;
    bottom: 10px;
  }
  .chat-box-min .text {
    display: none;
  }
  .chat-box-min .icon {
    display: block;
  }
  .chat-box-min .dot-wrapper {
    position: fixed;
    display: flex;
    bottom: 40px;
    right: 28px;
  }
  .chat-box-min .dot-wrapper .dot {
    margin-right: 3px !important;
    width: 4px !important;
    height: 4px !important;
    background-color: #ac0833;
  }
}
.chat-box {
  /* margin-left: 20px; */
  position: fixed;
  z-index: 100;
  max-width: 350px;
  width: calc(100% - 40px);
  bottom: 20px;
  right: 20px;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 22%);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.chat-header {
  height: 58px;
  background: var(--red-noti);
  border-radius: 0;
  border-bottom: 1px solid rgba(204, 207, 214, 0.5019607843137255);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0 20px;
}

.chat-header .chat-header-wrapper {
  color: white;
  font-weight: 900;
}

.chat-header .chat-header-wrapper .header-img {
  display: block;
  background: white;
  border-radius: 50%;
  padding: 6px;
}

.chat-header .chat-header-wrapper .header-img img {
  float: left;
  width: 24px;
}

.chat-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  height: calc(100% - var(--cb-header-height) - var(--cb-footer-height));
  padding: 20px;
  background-color: var(--cb-background-color);
}

.chat-body .welcome-message p {
  font-size: 1rem;
  text-align: justify;
}

.chat-box .box-message-log {
  height: 386px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  /* justify-content: flex-end; */
  overflow-y: auto;
  padding: 10px;
  background-color: var(--cb-background-color);
}

.emoji-list::-webkit-scrollbar,
.chat-box .box-message-log::-webkit-scrollbar {
  width: 8px;
  background: #f0f0f0;
}

.emoji-list::-webkit-scrollbar-thumb,
.chat-box .box-message-log::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.emoji-list::-webkit-scrollbar-track,
.chat-box .box-message-log::-webkit-scrollbar-track {
  border-radius: 0;
}

.box-message-log .message-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s;
  margin-top: 5px;
}

.message-right {
  align-items: flex-end;
}

.message-left {
  align-items: flex-start;
}

.box-message-log .user-name {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 10px;
  font-family: inherit;
  margin-bottom: 8px;
}

.box-message-log .user-name img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.box-message-log .text {
  font-size: 1rem;
  padding: 8px 14px;
  display: block;
  box-sizing: border-box;
  border-radius: 16px;
  display: block;
  max-width: 260px;
  font-weight: 400;
  overflow-wrap: break-word;
  animation-duration: 0.3s;
  animation-name: fly-in;
}

.box-message-log .msg-right {
  background-color: var(--red-noti);
  color: white;
}

.box-message-log .msg-left {
  background-color: white;
}

.chat-input {
  width: 100%;
  display: inline-flex;
  text-align: center;
  background: #fff;
  position: relative;
}

.chat-input input,
.chat-input label,
.chat-input button {
  margin: 0 10px;
}

.chat-input label,
.chat-input button {
  cursor: pointer;
}

.chat-input input {
  font-size: 1rem;
  position: relative;
  width: 100%;
  font-family: inherit;
  line-height: 35px;
  -webkit-font-smoothing: antialiased;
  outline: none;
  display: inline-block;
}

.chat-input button {
  height: 20px;
  background-color: #fff;
}

.chat-box-form-container {
  background: white;
  width: 100%;
  padding: 1rem;
  border-left: 5px solid var(--red-noti);
  border-radius: 5px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
}

.chat-box-form-control {
  position: relative;
  margin-bottom: 1rem;
  z-index: 0;
}

.chat-box-form-control .chat-box-input {
  height: 2.5rem;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  border: 1px solid #d9dbe4;
  padding: 0.5rem 1rem;
  background: #fff;
  color: #545454;
  box-sizing: border-box;
  margin: 0;
  border-radius: 5px;
  font-size: 0.812rem;
  -webkit-appearance: none;
}

textarea.chat-box-input {
  resize: none;
  height: 5rem !important;
}

.chat-box-form-control label {
  cursor: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: 0 100%;
  transform: translateY(0.7rem);
  color: #545454;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  margin-left: 1rem;
  display: block;
  font-size: 0.812rem;
  line-height: normal;
}

.chat-box-form-control .label-active {
  transform: translateY(-10px) scale(0.9);
  background-color: white;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
}

.chat-box-button-wrapper button {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--red-noti);
  color: #ffffff;
  text-transform: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
}

.chat-box-button-wrapper button:hover::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(247, 247, 249, 0.2);
  border-radius: 5px;
}

.chat-footer {
  font-size: 12px;
  /* line-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
  text-align: center;
  border-radius: 0 0 8px 8px;
  background-color: var(--cb-background-color);
  /* height: var(--cb-footer-height); */
  padding: 10px;
}

.chat-footer p {
  font-size: 0.8rem;
}

.close-chat-box {
  cursor: pointer;
  display: flex;
  padding: 8px;
  position: relative;
}

.close-chat-box:hover::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(247, 247, 249, 0.2);
  border-radius: 5px;
}

.emoji-wrapper {
  position: relative;
  transition: bottom 0.3s ease, opacity 0.3s ease;
}

.emoji-list {
  position: absolute;
  display: flex;
  bottom: 20px;
  right: -40px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 22%);
  width: 226px;
  background: white;
  border-radius: 5px;
  padding: 10px;
  max-height: 350px;
  overflow: auto;
}

.emoji-list li {
  cursor: pointer;
}

iframe {
  max-width: 100%
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";