.lSpg {
  display: none;
}

/* .lSAction > .lSPrev {
  background-image: url("../../images/icon/left.png");
}

.lSAction > .lSNext {
  background-image: url("../../images/icon/right.png");
} */

.product-slider .swiper-button-prev,
.product-slider .swiper-button-next {
  border-radius: 50%;
  background-color: #c4c4c4c9;
  color: rgb(119, 116, 116);
  top: 50%;
  width: 30px;
  height: 30px;
}

.product-slider .swiper-button-prev:hover,
.product-slider .swiper-button-next:hover {
  background-color: #ece7e7c9;
}

.product-slider .swiper-button-prev::after,
.product-slider .swiper-button-next::after {
  font-size: 20px;
}

.lSSlideOuter .lSPager.lSGallery li.active {
  border: 2px solid var(--blue-dior);
}

.lSPager li {
  height: 100%;
}

.lSSlideOuter .lSPager.lSGallery img {
  height: 100%;
  width: auto;
  margin: 0 auto;
  object-fit: contain;
}

.product__detail-content .lSSlideWrapper {
  height: 600px;
}

.lSAction > .lSPrev {
  background-position: 45% 50% !important;
}

.lSAction > .lSNext {
  background-position: 55% 50% !important;
}

.lSAction > a {
  background-color: #c4c4c4c9;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
  opacity: 0.8;
}

.product__images-slider .lslide,
.product__images-slider .clone {
  height: 100%;
  text-align: center;
}

.product__images-slider .lslide img,
.product__images-slider .clone img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Demo */
.option-title {
  margin-right: 3px;
}

.stars .checked {
  color: orange;
}

.rating .stars span {
  cursor: pointer;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.remove__upload-close {
  stroke: red;
}
.cancel-button {
  background: unset !important;
  color: #333 !important;
}

#imagemodal .modal-dialog {
  /*max-width: 40%;*/
}

#imagemodal .modal-content {
  /*max-width: 90vh;*/
  width: auto;
  height: 90vh;
}

#imagemodal .modal-content {
  position: relative;
}

#imagemodal .close-modal {
  position: absolute;
  top: 8px;
  right: 16px;
}

@media only screen and (max-width: 1400px) {
  .product__detail-content .lSSlideWrapper {
    height: 467.64px;
  }
}

@media only screen and (max-width: 1200px) {
  .product__detail-content .lSSlideWrapper {
    height: 381px;
  }
}

@media only screen and (max-width: 992px) {
  #imagemodal .modal-content {
    width: 80%;
    height: auto;
    /*max-height: 80vw;*/
  }

  .product__detail-content .lSSlideWrapper {
    height: 375px;
  }
}

@media only screen and (max-width: 768px) {
  #imagemodal .modal-dialog {
    max-width: 100%;
  }
  #h-full {
    height: 100% !important;
  }

  #imagemodal .modal-content {
    width: 100%;
    height: auto;
    /*max-height: 100vh;*/
  }

  .product__images-slider .lSAction > .lSPrev,
  .product__images-slider .lSAction > .lSNext {
    width: 18px;
    height: 100%;
    background-size: 12px;
    top: 0;
    margin-top: 0;
    border-radius: 0;
    opacity: 0.3;
  }

  .product__images-slider .lSAction > .lSNext {
    right: 0;
  }

  .product__images-slider .lSAction > .lSPrev {
    left: 0;
  }

  .gap-custom {
    gap: 20px;
  }
}

@media only screen and ((max-width: 768px) and (min-width: 640px)) {
  .lSSlideOuter .lSPager.lSGallery li {
    width: 110px;
  }
}

@media only screen and (max-width: 640px) {
  #imagemodal .modal-content {
    width: 100%;
    height: auto;
    /*max-height: 100vw;*/
  }
}

@media (min-width: 576px) {
  #sizechart-modal {
    max-width: 424px;
  }
}
