.cus-swiper-pagination {
    text-align: center;
    background-color: red;
}
.cus-swiper-pagination .swiper-pagination-bullet {
    width: 30px;
    height: 3px;
    border-radius: 0;
    background-color: rgba(255, 255, 255, .5);
}
.cus-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: white;
}
.banner-slider .swiper-button-prev,
.banner-slider .swiper-button-next {
    color: white;
    height: 100%;
    top: 0;
}

.banner-slider .swiper-button-prev::after,
.banner-slider .swiper-button-next::after {
    font-size: 20px;
}

.banner-slider .swiper-pagination-bullet {
    opacity: 1;
}
.banner-slider .swiper-pagination-bullet-active {
    background-color: white;
}

.brand-slider .swiper-button-prev,
.brand-slider .swiper-button-next {
    color: black;
    height: 100%;
    top: 0;
    margin-top: 0;
}
.brand-slider .swiper-button-prev {
    left: 0;
}
.brand-slider .swiper-button-next {
    right: 0;
}
.brand-slider .swiper-button-prev::after,
.brand-slider .swiper-button-next::after {
    font-size: 20px;
}

.brand-slider .swiper-button-prev:hover,
.brand-slider .swiper-button-next:hover { 
    background: rgba(0, 0, 0, 0.03);
}